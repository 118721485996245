exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-blog-post-1-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/blog/post1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-blog-post-1-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-omnigath-post-1-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/omnigath/post1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-omnigath-post-1-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-animal-mern-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/animal-mern/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-animal-mern-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-augmentation-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/data-augmentation/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-augmentation-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-cache-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/data-cache/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-cache-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-drug-review-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/drug-review/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-drug-review-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-esports-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/esports/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-esports-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-noledge-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/noledge/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-noledge-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-palmer-penguins-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/palmer-penguins/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-palmer-penguins-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-sentiment-analysis-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/sentiment-analysis/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-sentiment-analysis-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-shell-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/shell/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-shell-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-stockout-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/stockout/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-stockout-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-todo-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/todo/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-portfolio-todo-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-omnigath-index-js": () => import("./../../../src/pages/omnigath/index.js" /* webpackChunkName: "component---src-pages-omnigath-index-js" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-blog-post-1-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/blog/post1/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-blog-post-1-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-omnigath-post-1-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/omnigath/post1/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-omnigath-post-1-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-animal-mern-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/animal-mern/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-animal-mern-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-augmentation-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/data-augmentation/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-augmentation-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-cache-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/data-cache/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-cache-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-drug-review-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/drug-review/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-drug-review-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-esports-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/esports/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-esports-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-noledge-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/noledge/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-noledge-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-palmer-penguins-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/palmer-penguins/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-palmer-penguins-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-sentiment-analysis-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/sentiment-analysis/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-sentiment-analysis-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-shell-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/shell/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-shell-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-stockout-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/stockout/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-stockout-index-mdx" */),
  "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-todo-index-mdx": () => import("./../../../src/pages/omnigath/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/todo/index.mdx" /* webpackChunkName: "component---src-pages-omnigath-mdx-frontmatter-slug-js-content-file-path-src-portfolio-todo-index-mdx" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-blog-post-1-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/blog/post1/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-blog-post-1-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-omnigath-post-1-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/omnigath/post1/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-omnigath-post-1-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-animal-mern-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/animal-mern/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-animal-mern-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-augmentation-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/data-augmentation/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-augmentation-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-cache-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/data-cache/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-data-cache-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-drug-review-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/drug-review/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-drug-review-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-esports-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/esports/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-esports-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-noledge-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/noledge/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-noledge-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-palmer-penguins-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/palmer-penguins/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-palmer-penguins-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-sentiment-analysis-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/sentiment-analysis/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-sentiment-analysis-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-shell-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/shell/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-shell-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-stockout-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/stockout/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-stockout-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-todo-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/portfolio/todo/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-portfolio-todo-index-mdx" */)
}

